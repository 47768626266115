var body = document.querySelector("body");

/* Enable Transitions */
document.addEventListener(
	"DOMContentLoaded",
	function () {
		setTimeout(function () {
			document.body.classList.remove("no-transitions");
		}, 200);
	},
	false
);

//show the alert bell on load if hidden alerts are present
document.addEventListener("DOMContentLoaded", () => {
	const alertBell = document.querySelectorAll('.js-alert-bell');
	alertBell.forEach((bell) => {
		const hiddenAlerts = document.querySelectorAll('.alert.hidden-alert');
		if (hiddenAlerts.length > 0) {
			if (getParentElementWithClass(bell, 'mobile-secondary-nav')) {
				bell.style.display = 'block';
			}
			bell.disabled = false;
			bell.style.opacity = 1;
		} else {
			if (getParentElementWithClass(bell, 'mobile-secondary-nav')) {
				bell.style.display = 'none';
			}
			bell.disabled = true;
			bell.style.opacity = 0;
		}
	})
})

/* Dropdown Menu */
var dropItems = document.querySelectorAll(".main-nav > ul > li");
var mainNav = document.querySelector(".main-nav");
var navButtons = document.querySelectorAll(".header .nav-drop");

/*var wave = document.querySelector(".feature-wave");*/

var instance = new SV.HoverIntent(dropItems, {
	onEnter: function (targetItem) {
		if (window.innerWidth > 980) {
			closeLogin();
			closeSearch();

			targetItem.classList.add("active");
			

			body.classList.remove("nav-closed");
			body.classList.add("nav-open");

			const left =
				targetItem.offsetLeft + targetItem.offsetWidth / 2 - 23;
			document.documentElement.style.setProperty(
				"--navBeforeLeft",
				left + "px"
			);

			if (left > window.innerWidth - 200) {
				document.documentElement.style.setProperty(
					"--navBeforeColor",
					"rgba(0,0,0,0)"
				);
			}
			else if (left > window.innerWidth / 2) {
				document.documentElement.style.setProperty(
					"--navBeforeColor",
					"#2B553F"
				);
			} else {
				document.documentElement.style.setProperty(
					"--navBeforeColor",
					"#FFF"
				);
			}

			if (body.classList.contains("search-open")) {
				body.classList.remove("search-open");
			}
			if (body.classList.contains("login-open")) {
				body.classList.remove("login-open");
			}
		}
	},
	onExit: function (targetItem) {
		mainNav.removeAttribute("data-spot");
		targetItem.classList.remove("active");

		body.classList.add("nav-closed");
		body.classList.remove("nav-open");
	},
});

// menuHover(ditem);
//});



var randoms = document.querySelectorAll(".js-random-block");

randoms.forEach(function (bk) {
	var divs = bk.querySelectorAll(":scope > div");
	var spot = 0;
	if (divs.length > 0) {
		spot = Math.floor(Math.random() * divs.length);
	}

	for (var i = 0; i < divs.length; i++) {
		if (i != spot) {
			divs[i].style.display = "none";
		}
	}
	bk.classList.remove("unrandomized");
});

function indexInParent(node) {
	var children = node.parentNode.childNodes;
	var num = 0;
	for (var i = 0; i < children.length; i++) {
		if (children[i] == node) return num;
		if (children[i].nodeType == 1) num++;
	}
	return -1;
}

function menuHover(el) {
	var interval;
	var outInterval;
	var mel = el.querySelector(".dropdown-area");

	if (window.innerWidth > 980) {
		el.addEventListener("mouseleave", function (event) {
			interval = setInterval(function () {
				mel.style.display = "none";
			}, 500);
			if (outInterval) {
				clearInterval(outInterval);
			}
		});

		el.addEventListener("mouseover", function (event) {
			outInterval = setInterval(function () {
				mel.style.display = "block";
			}, 500);
			if (interval) {
				clearInterval(interval);
			}
		});
	}
}

function searchHover() {
	var mel = document.querySelector(".js-search");

	if (window.innerWidth > 980) {
		mel.addEventListener("mouseleave", function (event) {
			document.body.classList.remove("nav-hover");
		});

		mel.addEventListener("mouseover", function (event) {
			document.body.classList.add("nav-hover");
			const left =
				event.target.offsetLeft + event.target.offsetWidth / 2 - 9;
			document.documentElement.style.setProperty(
				"--navBeforeLeft",
				left + "px"
			);

			if (left > window.innerWidth / 2) {
				document.documentElement.style.setProperty(
					"--navBeforeColor",
					"#2B553F"
				);
			} else {
				document.documentElement.style.setProperty(
					"--navBeforeColor",
					"#FFF"
				);
			}
		});
	}
}
function loginHover() {
	var mel = document.querySelector(".js-login");

	if (window.innerWidth > 980) {
		mel.addEventListener("mouseleave", function (event) {
			document.body.classList.remove("nav-hover");
		});

		mel.addEventListener("mouseover", function (event) {
			document.body.classList.add("nav-hover");
			const left =
				event.target.offsetLeft + event.target.offsetWidth / 2 - 5;
			document.documentElement.style.setProperty(
				"--navBeforeLeft",
				left + "px"
			);

			if (left > window.innerWidth / 2) {
				document.documentElement.style.setProperty(
					"--navBeforeColor",
					"#2B553F"
				);
			} else {
				document.documentElement.style.setProperty(
					"--navBeforeColor",
					"#FFF"
				);
			}
		});
	}
}
searchHover();
loginHover();

document.addEventListener(
	"click",
	(element) => {
		if (window.innerWidth < 980) {
			if (element.target.matches(".mobile-nav .nav-header")) {
				element.preventDefault();
				element.target.parentNode.classList.toggle("active");
				if (element.target.parentNode.classList.contains("active")) {
					vSlideDown(
						element.target.parentNode.querySelector(
							".mobile-drop-content"
						)
					);
				} else {
					vSlideUp(
						element.target.parentNode.querySelector(
							".mobile-drop-content"
						)
					);
				}
			}
		}
		return;
	},
	true
);

/*** MAIN NAV ***/
//hide nav on anchor link of same page click in nav
window.addEventListener(
	"hashchange",
	function (event) {
		var drops = document.querySelectorAll(".nav-drop");

		drops.forEach(function (item) {
			item.style.display = "none";
		});
		const hashElement = document.querySelector(location.hash);

		if (hashElement) {
			if (hashElement.length > 0) {
				//Check Tabs
				if (
					document
						.querySelector(location.hash)
						.classList.contains("js-tab")
				) {
					var hs = document
						.querySelector(location.hash)
						.querySelector("button")
						.click();
				}
			}
		}
		
		//Check rates/accordion names
		var rates = document.querySelectorAll(".js-accordion-button");
		rates.forEach(function (rate) {
			if (
				rate.innerText
					.toLowerCase()
					.replace(/[^a-z0-9 ]/gim, "")
					.replace(/ /g, "-") ==
				location.hash.toLowerCase().replace("#", "")
			) {
				window.scrollTo(0, 0);
				rate.click();

				setTimeout(function () {
					rate.parentNode.scrollIntoView({ behavior: "smooth" });
				}, 1000);
			}
		});
	},
	false
);

//Check rates/accordion names
var rates = document.querySelectorAll(".js-accordion-button");
rates.forEach(function (rate) {
	if (
		rate.innerText
			.toLowerCase()
			.replace(/[^a-z0-9 ]/gim, "")
			.replace(/ /g, "-") == location.hash.toLowerCase().replace("#", "")
	) {
		window.scrollTo(0, 0);
		rate.click();
		setTimeout(function () {
			rate.parentNode.scrollIntoView({ behavior: "smooth" });
		}, 1000);
	}
});

/**
 *  RATE TABLE MOBILE SETUP
 */

var rates = document.querySelectorAll(".rate-table table");

rates.forEach(function (rt) {
	var trs = rt.querySelectorAll("tr");
	var headers = [];
	for (var i = 0; i < trs.length; i++) {
		var tds = trs[i].querySelectorAll("td,th");
		for (var j = 0; j < tds.length; j++) {
			if (i == 0) {
				headers.push(tds[j].innerText);
			}
			else {
				tds[j].setAttribute("data-title", headers[j]);
			}
		}
	}
});

/*** ALERTS ***/
function getCookie(name) {
	let value = `; ${document.cookie}`;
	let parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts.pop().split(";").shift();
	} else {
		return null;
	}
}
document.addEventListener(
	"click",
	(element) => {
		if (element.target.matches(".js-close-alert")) {
			if (getCookie("alerts")) {
				var cook =
					getCookie("alerts") +
					event.target.parentNode.parentNode.getAttribute(
						"data-alert-id"
					) +
					"|";
			} else {
					var cook = event.target.parentNode.parentNode.getAttribute(
						"data-alert-id"
					) + "|";
			}
			document.cookie =
				"alerts=" +
				cook +
				"; path=/; max-age=" +
				60 * 60 * 24 * 14 +
				";";

			vSlideUp(element.target.parentNode.parentNode);
			element.target.parentNode.parentNode.setAttribute(
				"data-hidden",
				"true"
			);
			element.target.parentNode.parentNode.classList.add("hidden-alert");
			const alertBell = document.querySelectorAll('.js-alert-bell');
			alertBell.forEach((bell) => {
				bell.disabled = false;
				bell.style.opacity = 1;
				if (getParentElementWithClass(bell, 'mobile-secondary-nav')) {
					bell.style.display = "block";
					document.querySelector('.mobile-secondary-nav').style.gridTemplateColumns = "1fr 1fr 1fr 1fr";
				}
			})
			
		}
	},
	false
);

/** CAROUSELS **/
const mobileSplide = document.querySelector(".mobile-splide");
if (mobileSplide) {
	new Splide(".mobile-splide", {
		mediaQuery: "min",
		perPage: 1,
		autoWidth: true,
		type: "loop",
		breakpoints: {
			980: {
				destroy: true,
			},
		},
	}).mount();
}

const blogSplide = document.querySelector(".blog-splide");
if (blogSplide) {
	new Splide(".blog-splide", {
		mediaQuery: "min",
		perPage: 1,
		type: "loop",
		gap: "1em",
		perMove: 1,
		breakpoints: {
			980: {
				/*				perPage: 3,*/
				padding: "34%",
				gap: "1em",
				perMove: 1,
			},
		},
	}).mount();
}
const blogSplideHorizontal = document.querySelector(".blog-splide-horizontal");
if (blogSplideHorizontal) {
	new Splide(".blog-splide-horizontal", {
		mediaQuery: "min",
		perPage: 1,
		type: "loop",
		gap: "1em",
		perMove: 1,
		breakpoints: {
			980: {
				/*				perPage: 3,*/
				type: "slide"
			},
		},
	}).mount();
}

const blogSplideMobile = document.querySelector(".blog-splide-mobile");
if (blogSplideMobile) {
    new Splide(".blog-splide-mobile", {
        mediaQuery: "min",
        perPage: 1,
        type: "loop",
        gap: "1em",
        perMove: 1,
        breakpoints: {
            980: {
                destroy: true,
            },
        },
    }).mount();
}

/*** SCRIPT ANIMATIONS ***/
//let observer = new IntersectionObserver(function (entries) {
//	entries.forEach(function (entry) {
//		// Log if the element and if it's in the viewport
//		if (entry.target.matches(".brick-split")) {
//			if (entry.isIntersecting) {
//				entry.target.timer = setInterval(function () {
//					entry.target.style.backgroundPosition =
//						120 -
//						-20 *
//							(entry.target.getBoundingClientRect().top /
//								entry.target.offsetHeight) +
//						"% 0";
//				}, 30);
//			} else {
//				clearInterval(entry.target.timer);
//			}
//		}
//	});
//});

/*** SCROLL ANIMATIONS ***/
let scrollObserver = new IntersectionObserver(
	function (entries) {
		entries.forEach(function (entry) {
			if (entry.target.matches(".scroll")) {
				if (entry.isIntersecting) {
					entry.target.classList.add("active");

					if (entry.target.matches(".count")) {
						setTimeout(function () {
							animateCountUp(entry.target);
						}, 500);
					}
				}
			}
			if (entry.target.matches(".with-arrow")) {
				if (entry.isIntersecting) {
					entry.target.timer = setInterval(function () {
						entry.target.style.setProperty(
							"--bar-position",
							150 -
								150 *
									((window.innerHeight -
										entry.target.getBoundingClientRect()
											.top) /
										(window.innerHeight +
											entry.target.offsetHeight)) +
								"px"
						);
					}, 30);
				} else {
					clearInterval(entry.target.timer);
				}
			}
		});
	},
	{ threshold: 0.001 }
);

let underlineObserver = new IntersectionObserver(
	function (entries) {
		entries.forEach(function (entry) {
			if (entry.target.matches("strong")) {
				if (entry.isIntersecting) {
					entry.target.classList.add("active");

					/* specific class functions can run here
                if (entry.target.matches(".count")) {
                    animateCountUp(entry.target);
                }
                */
				}
			}
		});
	},
	{ threshold: 0.001 }
);

let scrolls = document.querySelectorAll(".scroll");
let underlineAnimations = document.querySelectorAll("strong");

scrolls.forEach(function (item) {
	scrollObserver.observe(item);
});
underlineAnimations.forEach(function (item) {
	underlineObserver.observe(item);
});

///*** STICKY HEADER ***/
//// get the sticky element
//const stickyElm = document.querySelector('.headers')

//const stickyObserver = new IntersectionObserver(function(entries) {
//    entries.forEach(function (entry) {
//        if (entry.isIntersecting < 1) {
//            //Sticky

//            entry.target.classList.remove('unSticky');
//            entry.target.classList.remove('wasSticky');
//            entry.target.classList.add('wasUnSticky');

//            if (!entry.target.classList.contains("isSticky")) {
//                setTimeout(function () {
//                    entry.target.classList.add('isSticky');
//                }, 50);
//            }

//        }
//        else {
//            //Non Sticky
//            if (entry.target.classList.contains("isSticky")) {
//                entry.target.classList.remove('isSticky');
//                entry.target.classList.remove('wasUnSticky');
//                entry.target.classList.add('wasSticky');

//                if (!entry.target.classList.contains("unSticky")) {
//                    setTimeout(function () {
//                        entry.target.classList.add('unSticky');
//                    }, 50);
//                }
//            }

//        }

//    });
//},
//  {threshold: [1]}
//);
//stickyObserver.observe(stickyElm);

/*** ESCAPE KEY CLOSURES ***/
document.body.addEventListener("keypress", function (e) {
	if (e.key == "Escape") {
		if (
			document.querySelector(".js-search-area").style.display == "block"
		) {
			document.querySelector(".js-search-close").click();
		}
		if (document.querySelector(".js-login-area").style.display == "block") {
			document.querySelector(".js-login-close").click();
		}
	}
});

/** Search ***/
function openSearch() {
	body.classList.remove("nav-closed");
	body.classList.remove("login-open");

	closeAllMenus("search");

	body.classList.add("search-open");
	body.classList.remove("search-closed");

	var searchBox = document.querySelector(".header-search .search-inp");
	setTimeout(function () {
		searchBox.focus();
	}, 800);

	if (window.innerWidget < tablet_break) {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}
}

function closeSearch() {
	body.classList.remove("search-open");
	body.classList.add("nav-closed");
}

/** Menu **/
function openMenu() {
	var btn = document.querySelector(".js-mobile-nav-area");
	document.querySelector(".js-nav-mobile").classList.add("open");
	btn.classList.add("menu-open");
	document.body.classList.add("nav-open");
	document.querySelector("body").classList.add("menu-open");
}
function closeMenu() {
	document.body.classList.remove("nav-open");
	document.querySelector(".js-nav-mobile").classList.remove("open");
	document.querySelector("body").classList.remove("menu-open");
}

/** Login **/
function openLogin() {
	var btn = document.querySelector(".js-login-area");


	var mb_img = document.querySelectorAll(".js-login img");
	var mb_txt = document.querySelectorAll(".js-login span");

	body.classList.remove("nav-closed");
	closeAllMenus("login");

	body.classList.add("login-open");
	body.classList.remove("login-closed");
	//body.classList.remove("search-open");

	var loginBox = document.querySelector(".login-form #userid");
	//loginBox.focus();
	btn.scrollTop = 0;

	document.querySelectorAll('.js-login').forEach((it) => {
		it.style.opacity = 0;
	})

	setTimeout(() => {
		mb_img.forEach(function (it) { it.src = "/Content/Images/ico-close.svg"; });
		mb_txt.forEach(function (it) { it.innerText = "Close"; });
		document.querySelectorAll('.js-login').forEach((it) => {
			it.style.opacity = 1;
		})
	}, 200);
	
}
function closeLogin() {
	var btn = document.querySelector(".js-login-area");
	var mb_img = document.querySelectorAll(".js-login img");
	var mb_txt = document.querySelectorAll(".js-login span");

	if (body.classList.contains('login-open')) {
		body.classList.add("nav-closed");
		body.classList.remove("login-open");
		body.classList.add("login-closed");

		document.querySelectorAll('.js-login').forEach((it) => {
			it.style.opacity = 0;
		})

		setTimeout(() => {
			mb_img.forEach(function (it) { it.src = "/Content/Images/ico-login.svg"; });
			mb_txt.forEach(function (it) { it.innerText = "Login"; });
			document.querySelectorAll('.js-login').forEach((it) => {
				it.style.opacity = 1;
			})
		}, 200);
	}

	


}

function closeAllMenus(menu) {
	if (menu == "nav") {
		closeLogin();
		closeSearch();
	}
	if (menu == "search") {
		closeLogin();
		closeMenu();
	}
	if (menu == "login") {
		closeSearch();
		closeMenu();
	}
}

/*** Search Window ***/
document.addEventListener(
	"click",
	function (e) {
		//Search Open
		if (e.target.matches(".js-search")) {
			const left = e.target.offsetLeft + e.target.offsetWidth / 2 - 9;
			document.documentElement.style.setProperty(
				"--navBeforeLeft",
				left + "px"
			);

			document.documentElement.style.setProperty(
				"--navBeforeColor",
				"rgba(0,0,0,0)"
			);

			if (!body.classList.contains("search-open")) {
				openSearch();
			} else {
				closeSearch();
			}
		}
		//Search Close
		else if (e.target.matches(".js-search-close")) {
			closeSearch();
		}
		//Search Close
		else if (e.target.matches(".play-btn .photo")) {
			var par =
				e.target.parentElement.parentElement.querySelectorAll(
					"[data-video]"
				);
			par.forEach(function (vid) {
				if (vid.getAttribute("data-video") != "") {
					vid.click();
					return;
				}
			});
		}
		//Login Open
		else if (e.target.matches(".js-login")) {
			if (e.target.tagName == 'A') {
				e.preventDefault();
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth",
				});
			}
			var btn = document.querySelector(".js-login-area");
			if (body.classList.contains("login-open")) {
				closeLogin();
			} else {
				openLogin();
				const left =
					e.target.offsetLeft + e.target.offsetWidth / 2 - 11;
				document.documentElement.style.setProperty(
					"--navBeforeLeft",
					left + "px"
				);
			}
			document.documentElement.style.setProperty(
				"--navBeforeColor",
				"rgba(0,0,0,0)"
			);

			if (e.target.classList.contains("login-mobile")) {
				var sp = e.target.querySelector("span");
				if (sp.innerText.trim() == "Close") {
					sp.innerText = "Close";
					e.target.classList.add("active");
				} else {
					sp.innerText = "Login";
					e.target.classList.remove("active");
				}
			}
		}
		//Login Close
		else if (e.target.matches(".js-login-close")) {
			closeLogin();
		}
		//Mobile Dropdown/Accordion
		else if (e.target.matches(".js-mobile-dropdown-btn")) {
			var content = e.target.parentNode.querySelector(
				".js-mobile-dropdown-content"
			);
			var dropButton = e.target;
			if (dropButton.classList.contains("open")) {
				dropButton.classList.remove("open");
				vSlideUp(content);
			} else {
				dropButton.classList.add("open");
				vSlideDown(content);
			}
		}
		//Login Mobile Dropdown
		else if (e.target.matches(".js-login-drop")) {
			var btn = e.target.parentNode.querySelector("ul");
			if (btn.style.display != "block") {
				vSlideDown(btn); //.style.display = "block";
				e.target.parentNode.classList.add("active");
			} else {
				vSlideUp(btn); //btn.style.display = "none";
				e.target.parentNode.classList.remove("active");
			}
		}
		//Mobile Nav Open
		else if (e.target.matches(".js-nav-mobile")) {
			closeAllMenus("nav");
			if (e.target.classList.contains("open")) {
				closeMenu();
			} else {
				openMenu();
			}
		}
		//Mobile Nav Close
		else if (e.target.matches(".js-nav-mobile-close")) {
			var btn = document.querySelector(".js-mobile-nav-area");
			btn.classList.remove("menu-open");
			document.body.classList.remove("nav-open");
			// vSlideUp(btn);
		} else if (
			e.target.matches("[data-video]") &&
			!e.target.parentNode.classList.contains("cta-buttons") &&
			e.target.parentNode.id != "ctaButtons"
		) {
			if (e.target.getAttribute("data-video") != "") {
				e.preventDefault();
				let videoTitle = "";
				let videoGUID = "";
				if (
					e.target.querySelector("span.visuallyhidden").length > 0 &&
					e.target.querySelector("span.visuallyhidden").innerText !=
					""
				) {
					videoTitle = e.target.querySelector(
						"span.visuallyhidden"
					).innerText;
				}
				var vidBuild =
					"<div class='video-modal-content'><button class='video-modal-close'>&times;<span class='visuallyhidden'> close</span></button>";
				if (
					e.target.getAttribute("data-video").indexOf("vimeo") != -1
				) {
					var vid = e.target
						.getAttribute("data-video")
						.replace("https://vimeo.com/", "");
					if (vid.indexOf("/") != -1) {
						vid = vid.substring(0, vid.indexOf("/"));
					}
					vidBuild +=
						"<div style='padding: 56.25% 0 0 0; position: relative;'><iframe src='https://player.vimeo.com/video/" +
						vid +
						"?title=0&byline=0&portrait=0' alt='" +
						videoTitle +
						"' style='position:absolute;top:0;left:0;width:100%;height:100%;' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe></div><script src='https://player.vimeo.com/api/player.js'></script>";
					vidBuild +=
						"<div class='transcript-container js-accordion'><button class='transcript-button js-accordion-button' type='button' aria-expanded='false'>Video Transcript <img src='/Content/images/arrow-down.svg' width='19' height='10' alt='' /></button><div class='transcript-content js-accordion-content'><p>This video uses Vimeo's native closed captioning features to provide a transcript. If closed captioning is unavailable for this video, please <a href='/contact'>contact us</a> and we can provide you with one.</p></div></div>";
				} else if (
					e.target.getAttribute("data-video").indexOf("youtube") != -1
				) {
					var vid = e.target
						.getAttribute("data-video")
						.replace("https://www.youtube.com/watch?v=", "");
					if (vid.indexOf("/") != -1) {
						vid = vid.substring(0, vid.indexOf("/"));
					}
					vidBuild +=
						"<div style='padding: 56.25% 0 0 0; position: relative;'><iframe src='https://www.youtube.com/embed/" +
						vid +
						"' alt='" +
						videoTitle +
						"' style='position:absolute;top:0;left:0;width:100%;height:100%;' frameborder='0' allow='accelerometer; autoplay; encrypted - media; gyroscope; picture -in -picture; fullscreen' allowfullscreen></iframe></div>";
					vidBuild +=
						"<div class='transcript-container js-accordion'><button class='transcript-button js-accordion-button' type='button' aria-expanded='false'>Video Transcript <img src='/Content/images/arrow-down.svg' width='19' height='10' alt='' /></button><div class='transcript-content js-accordion-content'><p>This video uses YouTube's native closed captioning features to provide a transcript. If closed captioning is unavailable for this video, please <a href='/contact'>contact us</a> and we can provide you with one.</p></div></div>";
				} else if (
					e.target.getAttribute("data-video").indexOf(".mp4")
				) {
					videoGUID = e.target
						.getAttribute("data-video")
						.split("/")[2];
					vidBuild +=
						"<video width='100%' controls autoplay><source src='" +
						e.target.getAttribute("data-video") +
						"' type='video/mp4'></video>";
					// Since the transcript is queried async, make a holder for it and we'll append the content after the async completes
					vidBuild +=
						"<div class='transcript-container js-accordion'><button class='transcript-button js-accordion-button' type='button' aria-expanded='false'>Video Transcript <img src='/Content/images/arrow-down.svg' width='19' height='10' alt='' /></button><div id='transcript-" +
						videoGUID +
						"' class='transcript-content js-accordion-content'><p>For a transcript of this video, please <a href='/contact'>contact us</a>.</p></div></div>";
				}
				vidBuild += "</div>";

				var vidModal = document.querySelector(".video-modal");
				if (vidModal !== null) {
					vidModal.parentNode.removeChild(vidModal);
				}

				var div = document.createElement("div");
				div.classList.add("video-modal");
				div.innerHTML = vidBuild;

				document.body.appendChild(div);

				if (
					videoGUID != "" &&
					document.getElementById("transcript-" + videoGUID) !=
					undefined
				) {
					// If it's embedded, get the transcript
					let xhr = new XMLHttpRequest();
					xhr.open("GET", "/transcript/" + videoGUID);
					xhr.send();
					xhr.onload = function () {
						if (
							xhr.status === 200 &&
							xhr.responseText.replace(/\"/gm, "") != ""
						) {
							// console.log("Transcrpt Retrieval Succeeded, Status: " + xhr.status + ".");
							document.getElementById(
								"transcript-" + videoGUID
							).innerHTML =
								"<p>" +
								xhr.responseText
									.replace(/\"/gm, "")
									.replace(/\\r\\n/gm, "<br />") +
								"</p>";
						} else {
							// console.log("Transcrpt Retrieval Failed, Status: " + xhr.status + ".");
						}
					};
					xhr.onerror = function () {
						// console.log("Transcript Retrieval Error: " + xhr.status + ".");
					};
				}
			}
		} else if (e.target.matches(".video-modal")) {
			e.target.parentNode.removeChild(e.target);
		} else if (e.target.matches(".video-modal-content")) {
			e.stopPropagation();
		} else if (e.target.matches(".video-modal-close")) {
			e.preventDefault();
			var vidModal = document.querySelector(".video-modal");
			if (vidModal !== null) {
				vidModal.parentNode.removeChild(vidModal);
			}
		}
		//Footer Nav
		else if (e.target.matches(".footer-nav > ul > li > a")) {
			if (window.innerWidth < tablet_break) {
				e.preventDefault();
				var btn = e.target.parentNode.querySelector("ul");
				if (btn.style.display != "block") {
					vSlideDown(btn); //.style.display = "block";
					e.target.parentNode.classList.add("active");
				} else {
					vSlideUp(btn); //btn.style.display = "none";
					e.target.parentNode.classList.remove("active");
				}
			}
		} else if (e.target.matches(".js-accordion-header")) {
			var cnt = e.target.parentNode.parentNode.parentNode.querySelector(
				".js-accordion-content"
			);
			e.target.parentNode.parentNode.parentNode.classList.add("open");
			vSlideDown(cnt);
		} else if (e.target.matches(".js-accordion-close")) {
			var cnt = e.target.parentNode.parentNode.parentNode.querySelector(
				".js-accordion-content"
			);
			e.target.parentNode.parentNode.parentNode.classList.remove("open");
			vSlideUp(cnt);
		} else if (e.target.matches(".js-branch-selector")) {
			closeAllMenus("branch");

			document.body.classList.add("branch-open");
		} else if (e.target.matches(".js-branch-modal-close")) {
			document.body.classList.remove("branch-open");
		} else if (e.target.matches(".js-branch-modal")) {
			//Hide Locations Modal
			if (e.target.id == "locationsModal") {
				document.body.classList.remove("branch-open");
			}
		} else if (e.target.matches(".js-subscribe")) {
			//Hide Locations Modal
			if (e.target.parentNode.classList.contains("open")) {
				e.target.parentNode.classList.remove("open");
				e.target.innerText = e.target.getAttribute("data-text");
			} else {
				e.target.parentNode.classList.add("open");
				e.target.setAttribute("data-text", e.target.innerText);
				e.target.innerText = "Close";
			}
		} else if (e.target.matches(".js-alert-bell")) {
			var alerts = document.querySelectorAll(".hidden-alert");
			document.cookie =
				"alerts=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
			alerts.forEach(function (alert) {
				vSlideDown(alert);
			});
			e.target.disabled = true;
			e.target.style.opacity = 0;
			const alertBell = document.querySelectorAll('.js-alert-bell')
			alertBell.forEach((bell) => {
				bell.disabled = true;
				bell.style.opacity = 0;
				if (getParentElementWithClass(bell, 'mobile-secondary-nav')) {
					bell.style.display = "none";
					document.querySelector('.mobile-secondary-nav').style.gridTemplateColumns = "1fr 1fr 1fr";
				}
			})
		} else if (e.target.matches(".js-bc-video")) {
			//document.querySelector(".js-bc-video").parentNode.parentNode.parentNode.querySelector("img").click();
			e.target.parentNode.parentNode.parentNode
				.querySelector(".photo img")
				.click();
		} else if (e.target.matches(".jump-lnk")) {
			const lnkText = e.target.innerText.replace(/ /g, "_");
			e.target.parentNode.parentNode.classList.remove("open");
			const btn = document.querySelectorAll("[data-jumplink='" + lnkText + "']");
			if (btn.length > 0) {
				btn[0].scrollIntoView({ behavior: "smooth" });
			}
			var tg = e.target;
			setTimeout(function () {
				var jumps = document.querySelectorAll( ".js-links .jump-lnk" );
				jumps.forEach(function (jp) {
					jp.classList.remove("active");
				});
				tg.classList.add("active");
			}, 600);
		}
		// Comparison Chart Mobile Previous
		else if (e.target.matches(".js-table-prev")) {
			var base = e.target.parentNode.parentNode; //$(this).parent().parent();
			base.querySelector(".js-table-next").classList.remove("grayed");
			base.setAttribute(
				"data-col",
				Number(base.getAttribute("data-col")) - 1
			);
			if (base.getAttribute("data-col") == 1) {
				e.target.classList.add("grayed");
			}
			var spot = base.querySelector("table tr");
			var ths = spot.querySelectorAll("th");
			base.querySelector(".js-rate-head").innerHTML = eq.call(
				ths,
				base.getAttribute("data-col")
			).innerHTML;
			updateDots(base, base.dataset.col);
			mobileButtonDisplay(base.dataset.col);
		}
		// Comparison Chart Mobile Next
		else if (e.target.matches(".js-table-next")) {
			var base = e.target.parentNode.parentNode; //$(this).parent().parent();
			base.querySelector(".js-table-prev").classList.remove("grayed");
			base.setAttribute(
				"data-col",
				Number(base.getAttribute("data-col")) + 1
			);
			var spot = base.querySelector("table tr");
			var ths = spot.querySelectorAll("th");

			if (base.getAttribute("data-col") == ths.length - 1) {
				e.target.classList.add("grayed");
			}

			base.querySelector(".js-rate-head").innerHTML = eq.call(
				ths,
				base.getAttribute("data-col")
			).innerHTML;
			updateDots(base, base.dataset.col);
			mobileButtonDisplay(base.dataset.col);
		} else if (e.target.matches(".pagination-dots .dot")) {
			const compareTable = e.target.parentNode.parentNode.querySelector('.compare-table');
			var spot = compareTable.querySelector("table tr");
			var ths = spot.querySelectorAll("th");
			compareTable.dataset.col = e.target.dataset.col;
			updateDots(compareTable);
			compareTable.querySelector(".js-table-prev").classList.remove("grayed");
			compareTable.querySelector(".js-table-next").classList.remove("grayed");
			if (compareTable.dataset.col == 1) {
				compareTable.querySelector(".js-table-prev").classList.add("grayed");
			}
			if (compareTable.dataset.col == ths.length - 1) {
				compareTable.querySelector(".js-table-next").classList.add("grayed");
			}
			
			compareTable.querySelector(".js-rate-head").innerHTML = eq.call(
				ths,
				compareTable.getAttribute("data-col")
			).innerHTML;

		} else if (e.target.matches(".js-accordion .js-close-btn")) {
			const parent = getParentElementWithClass(e.target, "js-accordion");
			parent.querySelector(".js-accordion-button").click();
		} else if (e.target.matches(".js-tab-header")) {
			//change tab

			var tabs =
				e.target.parentNode.parentNode.parentNode.querySelectorAll(
					".js-tab"
				);

			tabs.forEach(function (tab) {
				if (
					tab.getAttribute("data-tab") ==
					e.target.getAttribute("data-tab")
				) {
					tab.classList.add("active");
					moveSlideTrack(e.target);
					setTimeout(() => {
						tab.style.opacity = 1;
					}, 400)
					
				} else {
					tab.style.opacity = 0;
					setTimeout(() => {
						tab.classList.remove("active");
					}, 400)
					
				}
			});
			var headers =
				e.target.parentNode.parentNode.parentNode.querySelectorAll(
					".js-tab-header"
				);
			headers.forEach(function (tab) {
				if (
					tab.getAttribute("data-tab") ==
					e.target.getAttribute("data-tab")
				) {
					tab.classList.add("active");
				} else {
					tab.classList.remove("active");
				}
			});
		} else if (e.target.matches(".tab-name")) {
			//change tab
			e.target.classList.toggle("open");
			var tab = e.target.parentNode.querySelector(".tab-content");

			if (tab.classList.contains("tab-open")) {
				vSlideUp(tab);
				tab.classList.remove("tab-open");
			} else {
				vSlideDown(tab);
				tab.classList.add("tab-open");
			}
		}
        else if (e.target.matches(".jump-header")) {
            //change tab
            e.target.parentNode.classList.toggle("open");
        }
	},
	false
);

/** Tabs **/
var ptabs = document.querySelectorAll(".tabs");

ptabs.forEach(function (tab) {
	var tabs = tab.querySelectorAll(".js-tab");
	//Create Tab Nav
	var tabHeader = document.createElement("div");
	tabHeader.classList.add("tabs-nav");
	var cnt = document.createElement("div");
	cnt.classList.add("content");
	
	tabs.forEach(function (tab) {
		var rand = Math.floor(Math.random() * 10000000000000);
		var btn = document.createElement("button");
		btn.classList.add("js-tab-header");
		btn.setAttribute("data-tab", rand);
		tab.setAttribute("data-tab", rand);
		btn.innerHTML = tab.querySelector(".tab-name").innerHTML;
		tab.querySelector(".tab-name").style.display = "none";
		cnt.appendChild(btn);
	});

	const slideTrack = document.createElement('div');
	slideTrack.classList.add('js-slide-track');
	const slide = document.createElement('div');
	slide.classList.add('js-slide');
	slideTrack.appendChild(slide);
	cnt.appendChild(slideTrack);

	tabHeader.appendChild(cnt);

	if (tabs.length > 0) {
		tabs[0].parentNode.insertBefore(tabHeader, tabs[0]);
	}

	//Set first as active
	var tabBtns = tab.querySelectorAll(".js-tab-header");
	if (tabBtns.length > 0 && !tabs[0].classList.contains("no-auto-open")) {
		tabBtns[0].classList.add("active");
		tabs[0].classList.add("active");
		tabs[0].style.opacity = 1;
		moveSlideTrack(tabBtns[0]);
	}
});

//Move Nav Slide Track
function moveSlideTrack(activeEl) {
	const slideEl = activeEl.parentNode.querySelector('.js-slide-track > .js-slide');

	slideEl.style.left = activeEl.offsetLeft + "px";
	slideEl.style.width = activeEl.offsetWidth + "px";

	}

//Allow setting of tab via location hash
//if (window.location.hash) {
//	var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character

//	tabBtns.forEach(function (topic) {
//		if (
//			topic.innerText.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "") ==
//			hash.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")
//		) {
//			topic.click();
//		}
//	});
//}

/* Anchor Links Chrome Fix */
(function (document, history, location) {
	var HISTORY_SUPPORT = !!(history && history.pushState);

	var anchorScrolls = {
		ANCHOR_REGEX: /^#[^ ]+$/,
		OFFSET_HEIGHT_PX: 65,

		/**
		 * Establish events, and fix initial scroll position if a hash is provided.
		 */
		init: function () {
			this.scrollToCurrent();
			window.addEventListener(
				"hashchange",
				this.scrollToCurrent.bind(this)
			);
			document.body.addEventListener(
				"click",
				this.delegateAnchors.bind(this)
			);
		},

		/**
		 * Return the offset amount to deduct from the normal scroll position.
		 * Modify as appropriate to allow for dynamic calculations
		 */
		getFixedOffset: function () {
			return this.OFFSET_HEIGHT_PX;
		},

		/**
		 * If the provided href is an anchor which resolves to an element on the
		 * page, scroll to it.
		 * @param  {String} href
		 * @return {Boolean} - Was the href an anchor.
		 */
		scrollIfAnchor: function (href, pushToHistory) {
			var match, rect, anchorOffset;

			if (!this.ANCHOR_REGEX.test(href)) {
				return false;
			}

			match = document.getElementById(href.slice(1));

			if (match) {
				rect = match.getBoundingClientRect();
				anchorOffset =
					window.pageYOffset + rect.top - this.getFixedOffset();
				window.scrollTo({
					top: anchorOffset,
					left: window.pageXOffset,
					behavior: "smooth",
				});

				// Add the state to history as-per normal anchor links
				if (HISTORY_SUPPORT && pushToHistory) {
					history.pushState(
						{},
						document.title,
						location.pathname + href
					);
				}
			}

			return !!match;
		},

		/**
		 * Attempt to scroll to the current location's hash.
		 */
		scrollToCurrent: function () {
			this.scrollIfAnchor(window.location.hash);
		},

		/**
		 * If the click event's target was an anchor, fix the scroll position.
		 */
		delegateAnchors: function (e) {
			var elem = e.target;

			if (
				elem.nodeName === "A" &&
				this.scrollIfAnchor(elem.getAttribute("href"), true)
			) {
				e.preventDefault();
			}
		},
	};

	window.addEventListener(
		"DOMContentLoaded",
		anchorScrolls.init.bind(anchorScrolls)
	);
})(window.document, window.history, window.location);

//Compare Chart Highlight
var cmp = document.querySelectorAll(".compare-table");

if (cmp.length > 0) {
	var pageTitle = document.querySelector("h1");
	if (pageTitle != null) {
		cmp.forEach(function (table) {
			var ths = table.querySelectorAll("th");
			ths.forEach(function (th) {
				if (th.innerText == pageTitle.innerText) {
					var cellIndex = th.cellIndex;

					var tds = table.querySelectorAll("th,td");
					tds.forEach(function (td) {
						if (td.cellIndex == cellIndex) {
							td.classList.add("active");
						}
					});
				}
			});
		});
	}
}

/**
 * ADA - Remove id=pasted
 */
var ids = document.querySelectorAll("#isPasted");
ids.forEach(function (item) {
	item.removeAttribute("id");
});

/** Comparison Charts
 * Add controls on mobile **/
function eq(index) {
	if (index >= 0 && index < this.length) return this[index];
	else return -1;
}

/******* COUNTER ON SCROLL **********/
// How long you want the animation to take, in ms
const animationDuration = 2000;
// Calculate how long each �frame� should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 60;
// Use that to calculate how many frames we need to complete the animation
const totalFrames = Math.round(animationDuration / frameDuration);
// An ease-out function that slows the count as it progresses
const easeOutQuad = (t) => t * (2 - t);

// The animation function, which takes an Element
const animateCountUp = (el) => {
	let frame = 0;
	let money = "";
	if (el.innerHTML.indexOf("$") != -1) {
		money = "$";
	}
	console.log("Count to: " + el.innerHTML.replace(/\D/g, ""));
	const countTo = parseInt(el.innerHTML.replace(/\D/g, ""), 10);
	// Start the animation running 60 times per second
	const counter = setInterval(() => {
		frame++;
		// Calculate our progress as a value between 0 and 1
		// Pass that value to our easing function to get our
		// progress on a curve
		const progress = easeOutQuad(frame / totalFrames);
		// Use the progress value to calculate the current count
		const currentCount = Math.round(countTo * progress);

		// If the current count has changed, update the element
		if (parseInt(el.innerHTML.replace(",", ""), 10) !== currentCount) {
			el.innerHTML =
				money +
				currentCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}

		// If we�ve reached our last frame, stop the animation
		if (frame === totalFrames) {
			clearInterval(counter);
		}
	}, frameDuration);
};

var charts = document.querySelectorAll(".compare-block");

charts.forEach(function (chart) {
	var tab = chart.querySelector("table");

	var holder = chart.querySelector(".compare-table");
	var ths = tab.querySelector("tr").querySelectorAll("th");
	if (ths.length > 1) {
		holder.setAttribute("data-col", "1");
		var controls = document.createElement("div");
		controls.classList.add("table-controls");
		controls.innerHTML =
			"<button class='js-table-prev grayed'></button><span class='f-h2 js-rate-head'>" +
			eq.call(ths, 1).innerHTML +
			"</span><button class='js-table-next'></button>";

		holder.insertBefore(controls, holder.firstChild);
	}
});

/** JUMP NAVS ***/
var jumpObserver = new IntersectionObserver(
	function (entries) {
		entries.forEach(function (entry) {
			if (entry.target.matches("[data-jumplink]")) {
				if (entry.isIntersecting) {
					var jumps = document.querySelectorAll(
						".js-links .jump-lnk"
					);
					jumps.forEach(function (jp) {
						if (jp.innerText.trim() == entry.target.getAttribute("data-jumplink").replace(/_/g, " ").trim()) {
							jp.classList.add("active");
						} else {
							jp.classList.remove("active");
						}
					});
				}
			}
		});
	},
	{ rootMargin: "-50px 0px 0px 0px", threshold: [.00001] }
);
// The element to observe
let jumps = document.querySelectorAll("[data-jumplink]");
// Attach it to the observer
jumps.forEach(function (item) {
	jumpObserver.observe(item);
});

if (document.querySelectorAll(".js-calc").length > 0) {
	document.addEventListener(
		"click",
		function (e) {
			if (e.target.matches(".js-calc")) {
				var sc = document.createElement("script");
				sc.src =
					"https://www.calcxml.com/scripts/loadCalc.js?calcTarget=" +
					e.target.getAttribute("data-calcid") +
					"&skn=736&embed=2&tabs=1&secure=1";
				document.body.appendChild(sc);

				document
					.querySelectorAll(".js-calc.active")
					.forEach(function (item) {
						item.classList.remove("active");
					});
				e.target.classList.add("active");
			}
		},
		false
	);

	document.querySelector(".js-calc").click();
}

//Horizontal filter scrolling
if (document.querySelector(".js-horizontalScroll") != null) {
	for (el of document.querySelectorAll(".js-horizontalScroll")) {
		if (!el.getAttribute("style")) {
			el.setAttribute(
				"style",
				"--beforeElementOpacity: 0; --afterElementOpacity: 1"
			);
		}
		el.addEventListener("scroll", (e) => {
			if (e.target.scrollLeft == 0) {
				e.target.setAttribute(
					"style",
					"--beforeElementOpacity: 0; --afterElementOpacity: 1"
				);
				//document.documentElement.style.setProperty('--beforeElementOpacity', '1');
			} else if (
				e.target.scrollLeft + e.target.offsetWidth ==
				e.target.scrollWidth
			) {
				e.target.setAttribute(
					"style",
					"--afterElementOpacity: 0; --beforeElementOpacity: 1;"
				);
				//document.documentElement.style.setProperty('--afterElementOpacity', '0');
			} else {
				e.target.setAttribute(
					"style",
					"--afterElementOpacity: 1; --beforeElementOpacity: 1;"
				);
				//document.documentElement.style.setProperty('--afterElementOpacity', '1');
			}
		});
	}
}

//Scrolling Nav logic

//var lastScrollTop = 100;

//window.addEventListener(
//	"scroll",
//	function () {
//		// or window.addEventListener("scroll"....
//		if (!document.querySelector("hydrated")) {
//			var st = window.pageYOffset || document.documentElement.scrollTop; //
//			const headerDiv = document.querySelector(".headers");
//			if (st > lastScrollTop) {
//				headerDiv.classList.add("scrolling");
//				headerDiv.classList.remove("isSticky");
//			} else if (st < lastScrollTop) {
//				headerDiv.classList.add("isSticky");
//			} // else was horizontal scroll
//			lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
//		}
//	},
//	false
//);


var linkHolder = document.querySelector(".jump-nav.js-links");

if(linkHolder) {
    document.addEventListener('DOMContentLoaded', function () {
            var jumpLinks = document.querySelectorAll("[data-jumplink]");
		var linkHolder = document.querySelector(".jump-nav.js-links");
		var first = true;

            jumpLinks.forEach(function (lnk) {
                var btn = document.createElement("button");
                btn.innerText = lnk.getAttribute("data-jumplink").replace(/_/g," ");
                
				btn.classList.add("jump-lnk");
				if (first) {
					btn.classList.add("active");
					first = false;
				}
                linkHolder.append(btn);
            });

        }, false);
}

//Compare Table CTA logic

function mobileButtonDisplay(activeColumn) {
	const compareTable = document.querySelector(".compare-table");

	if (compareTable) {
		const buttonToMove = compareTable.querySelector(
			`.table > table > tbody > tr:last-of-type td:nth-child(${
				Number(activeColumn) + 1
			})`
		);
		if (buttonToMove) {
			const MBD = compareTable.querySelector(".mobileButtonDisplay");
			MBD.innerHTML = buttonToMove.innerHTML;
		}
	}
}

document.addEventListener("DOMContentLoaded", () => {
	mobileButtonDisplay(1);
});

const lnkTop = document.querySelector(".js-lnk-top");

if (lnkTop) {
	lnkTop.addEventListener("click", () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	});
}

//Comparison Table Pagination Dots Extravaganza
//           --Now in Smell-o-vision!

const updateDots = (base) => {
	const dots = base.parentNode.querySelectorAll('.pagination-dots .dot');
	dots.forEach((dot) => {
		if (dot.dataset.col == base.dataset.col) {
			dot.classList.add('active')
		} else {
			dot.classList.remove('active')
		}
	})
}

if (charts) {
	charts.forEach((chart) => {
		const paginationDots = chart.querySelector('.pagination-dots');
		if (paginationDots) {
			const numCols = (chart.querySelector('tr').childNodes.length - 1)

			if (numCols > 1) {
				for (let index = 0; index < numCols; index++) {
					const dot = document.createElement('button');
					dot.classList.add('dot');
					dot.dataset.col = (index + 1)
					
					paginationDots.append(dot);
				}	
			}
			updateDots(paginationDots.parentNode.querySelector('.compare-table'));
		}
	})
	
}

//add links to main pages on mobile

document.addEventListener('DOMContentLoaded', () => {
	const navHeaders = document.querySelectorAll('.mobile-nav .nav-header');

	navHeaders.forEach((menu) => {
		const newEl = document.createElement('li');
		const text = menu.innerText.toLowerCase();
		if (text == "explore sbsi") {
			newEl.innerHTML = `<a href="/${text.replace(/ /g, "-")}">Explore SBSI</a>`;
		} else {
			newEl.innerHTML = `<a style="text-transform: capitalize" href="/${text.replace(/ /g, "-")}">Explore ${text}</a>`;
		}
		
		menu.parentNode.querySelector('.main-links').prepend(newEl);

	})
})

////Sticky Jump Nav Observer
//const sidebarNav = document.querySelector(".sidebar");

//if (sidebarNav) {
//	document.addEventListener("scroll", (e) => {
//		sidebarNav.classList.toggle("is-sticky", sidebarNav.offsetTop <= window.scrollY);
//	});
//}

/*** STICKY JUMP NAV ***/
let sideBarObserver = new IntersectionObserver(function (entries) {
	entries.forEach(function (entry) {
		if (entry.target.matches(".sidebar")) {
			const rect = entry.target.getBoundingClientRect();
			if (!entry.isIntersecting) {
				if (rect.y < 200) {
					entry.target.classList.add("is-sticky");
				}
				
				
			}
			else {
				entry.target.classList.remove("is-sticky");
			}
		}
	});
}, { threshold: 1 });
let sideNavs = document.querySelectorAll(".sidebar");
sideNavs.forEach(function (item) {
	sideBarObserver.observe(item);
});

//const iconSections = document.querySelectorAll('.icon-section');

//iconSections.foreach(el => {
//	adjustGrid(el);
//})
//function adjustGrid(cardSection) {
//	let cards = cardSection.querySelector('.icons').children;
//	if (window.innerWidth < 900) {
//		cards[0].parentNode.style.gridTemplateColumns = "1fr";
//	} else {
//		let maxWidth = '200px';
//		if (cards[0].classList.contains('max-wrap-small')) {
//			maxWidth = "315px";
//		} else if (cards[0].classList.contains('max-wrap-medium')) {
//			maxWidth = "400px";
//		} else if (cards[0].classList.contains('max-wrap-large')) {
//			maxWidth = "525px";
//		}
//		if (cards.length == 4) {
//			if (((cards[0].offsetWidth + 55) * cards.length) > window.innerWidth) {
//				cards[0].parentNode.style.gridTemplateColumns = `minmax(240px, ${maxWidth}) minmax(320px, min(28vw,${maxWidth}))`
//			} else {
//				cards[0].parentNode.style.gridTemplateColumns = `repeat(auto-fit, minmax(320px, min(28vw,${maxWidth})))`
//			}
//		} else {
//			cards[0].parentNode.style.gridTemplateColumns = `repeat(auto-fit, minmax(320px, min(28vw,${maxWidth})))`
//		}
//	}
//}

		///////////////////////////////////////////////////////////////
		//															 //
		//			Mortgage lenders direct link logic				 //
		//								by troy.					 //
		///////////////////////////////////////////////////////////////

const isDirectoryIndex = document.querySelector('.directory-index');

if (isDirectoryIndex) {
	document.addEventListener('DOMContentLoaded', () => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const category = urlParams.get('category')

		if (category) {
			const filter = document.querySelector('.js-expertise');
			filter.value = category;

			const event = new Event('change');

			// Dispatch it.
			filter.dispatchEvent(event);

		}
	})
}

const isHome = document.querySelector("#pg-home");

if (isHome) {
	document.addEventListener('DOMContentLoaded', () => {
		const alerts = isHome.querySelectorAll('.alert');

		const alertCookie = getAlertCookie

		alerts.forEach((alert) => {

		})
	})
}

