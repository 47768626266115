/////////////////////////////////////////////////////////////////
//      Logic for the Operation of the Directory Widget        //
/////////////////////////////////////////////////////////////////

const directoryWidgets = document.querySelectorAll(".directory-box");

directoryWidgets.forEach((directoryWidget) => {
	const locationID = directoryWidget.querySelector(".js-location-id");
	const expertise = directoryWidget.querySelector(".js-expertise");
	const flyout = directoryWidget.querySelector(".directory-flyout");

	const displayItems = (content, dest) => {
		if (dest.parentNode.style.display == "none" || dest.parentNode.style.display == "") {
			if (content.trim() == "") {
				dest.innerHTML = "<h2 class='f-h2 no-results'>Sorry, no results found</h2>";
			} else {
				dest.innerHTML = content;
			}
			vSlideDown(dest.parentNode);
		} else {
			dest.style.opacity = 0;

			setTimeout(() => {
				if (content.trim() == "") {
					dest.innerHTML = "<h2 class='f-h2 no-results'>Sorry, no results found</h2>";
				} else {
					dest.innerHTML = content;
				}
				dest.style.opacity = 1;
			}, 500);
		}
		directoryWidget.querySelector(".content").classList.add("flyout-open");
		/*    directoryWidget.querySelector('.flyout-open').style.backgroundColor = window.getComputedStyle(dest).backgroundColor;*/
	};

	if (directoryWidget.querySelector(".content.meet-the-team")) {
		let directoryElements = document.querySelectorAll(".directory-index .directory-item");

		directoryElements = dontShowDuplicates(directoryElements);

		document.querySelector(".js-directory-show-all").addEventListener("click", (e) => {
			directoryElements.forEach((el) => {
				el.style.display = "grid";
				locationID.value = "";
				expertise.value = "";
			});
			e.target.style.display = "none";
		});

		locationID.addEventListener("change", (e) => {
			let foundResults = false;
			directoryElements.forEach((el) => {
				if (el.dataset.location && el.dataset.location.includes(e.target.value)) {
					el.style.display = "grid";
					if (
						expertise.value &&
						el.dataset.expertise &&
						!el.dataset.expertise.includes(expertise.value)
					) {
						el.style.display = "none";
					}
				} else {
					el.style.display = "none";
				}
				if (el.style.display == "grid") {
					foundResults = true;
				}
			});
			foundResults
				? (document.querySelector(".noResults").style.display = "none")
				: (document.querySelector(".noResults").style.display = "block");
			document.querySelector(".js-directory-show-all").style.display = "block";
			dontShowDuplicates(directoryElements);
		});
		expertise.addEventListener("change", (e) => {
			let foundResults = false;
			directoryElements.forEach((el) => {
				if (el.dataset.expertise && el.dataset.expertise.includes(e.target.value)) {
					el.style.display = "grid";
					if (
						locationID.value &&
						el.dataset.location &&
						!el.dataset.location.includes(locationID.value)
					) {
						el.style.display = "none";
					}
				} else {
					el.style.display = "none";
				}
				if (el.style.display == "grid") {
					foundResults = true;
				}
			});
			foundResults
				? (document.querySelector(".noResults").style.display = "none")
				: (document.querySelector(".noResults").style.display = "block");
			document.querySelector(".js-directory-show-all").style.display = "block";

			dontShowDuplicates(directoryElements);
		});

		function dontShowDuplicates(entries) {
			if (entries.length > 1) {
				const allNames = [];

				entries.forEach((el, index) => {
					const name = el.querySelector('.name').innerHTML;

					if (allNames.indexOf(name) == -1) {
						console.log(allNames.indexOf(name))
						allNames.push(name)
					} else {
						el.style.display = "none";
					}
				})
			}
			return entries;
		}
	} else {
		locationID.addEventListener("change", async (e) => {
			if (expertise) {
				if (expertise.value != "") {
					const data = await fetch(
						`/api/v1/GetEmployeesByLocationAndExpertise?locationID=${e.target.value}&expertise=${expertise.value}`
					);
					if (data.ok) {
						const results = await data.text();
						displayItems(results, flyout.querySelector(".content"));
					}
				}
			} else {
				let data = "";
				if (e.target.dataset.default) {
					data = await fetch(
						`/api/v1/GetEmployeesByLocationAndExpertise?locationID=${e.target.value}&expertise=${e.target.dataset.default}`
					);
				} else {
					data = await fetch(
						`/api/v1/GetEmployeesByLocationAndExpertise?locationID=${e.target.value}`
					);
				}

				if (data.ok) {
					const results = await data.text();
					displayItems(results, flyout.querySelector(".content"));
				}
			}
		});
		if (expertise) {
			expertise.addEventListener("change", async (e) => {
				if (locationID.value != "") {
					const data = await fetch(
						`/api/v1/GetEmployeesByLocationAndExpertise?locationID=${locationID.value}&expertise=${e.target.value}`
					);
					if (data.ok) {
						const results = await data.text();
						displayItems(results, flyout.querySelector(".content"));
					}
				}
			});
		}
	}
});

const bioModalButtons = document.querySelectorAll('button.has-bio.js-show-modal');

bioModalButtons.forEach(button => {
	button.addEventListener('click', () => {
		const directoryContent = button.parentElement;
		console.log(directoryContent);
		const modalInfo = directoryContent.querySelector('.modal-info');

		if (document.querySelector('body > .bio-modal')) {
			document.querySelector('body > .bio-modal').innerHTML = modalInfo.innerHTML;
		} else {
			const modal = document.createElement('div');
			modal.className = "bio-modal";
			modal.innerHTML = modalInfo.innerHTML;

			body.appendChild(modal);
		}
		document.querySelector('body > .bio-modal').style.display = 'flex';

	})
})
document.addEventListener('click', (e) => {
	if (e.target.classList.contains('bio-modal') || e.target.classList.contains('bio-modal-close')) {
		const bioModal = document.querySelector('body > .bio-modal');
		body.removeChild(bioModal);
	}
})