/////////////////////////////////////////////////////
//              Featured Articles Logic            //
/////////////////////////////////////////////////////

async function getFeaturedArticles() {
		const data = await fetch("/api/v1/featuredArticles");
		if (data.ok) {
			result = await data.json();
			return result;
		} else {
			console.log("Error obtaining featured articles")
		}
}

async function getMoreArticles(pageNumber = 0, amount = 9, category = "all", append = false) {
	const blogContainer = document.querySelector(".blog-block .blog-articles .articles");
	const BlogBtn = document.querySelector('.js-load-more');
	const data = await fetch(`/Blog/MoreArticles/${pageNumber}/${category}/${amount}`);

	if (data.ok) {
		const results = await data.text();
		/*            console.log(results);*/
		if (append) {
			blogContainer.innerHTML += results;
		} else {
			blogContainer.innerHTML = results;
		}

		if (blogContainer.innerHTML.trim() == '') {
			blogContainer.innerHTML = "<h3 style='grid-column:1/4; text-align:center; '>Sorry, no articles found</h3>";
		}
		if (results.trim() == "" || blogContainer.querySelectorAll(".article").length < 9) {
			BlogBtn.style.opacity = .1;
			BlogBtn.disabled = true;
		} else {
			BlogBtn.style.opacity = 1;
			BlogBtn.disable = false;
		}
		BlogBtn.setAttribute("data-page", parseInt(BlogBtn.getAttribute("data-page")) + 1);
/*		BlogBtn.removeAttribute("disabled");*/

	}
}

const initBlogPagination = (numArticles) => {
	const dotContainer = document.querySelector(".pagination-dots");
	for (let x = 0; x < numArticles; x++) {
		const dotEl = document.createElement("button");
		dotEl.classList.add("dot");
		dotEl.dataset.article = x;
		dotEl.innerHTML = `<span class="visuallyhidden">Carousel Dot</span>`
		dotContainer.appendChild(dotEl);
	}
};

const setButtons = (articles) => {
	const activeButton = document.querySelector(".carousel-controls .dot.active");
	const prev = document.querySelector(".carousel-controls .prev-button");
	const next = document.querySelector(".carousel-controls .next-button");
	if (activeButton.dataset.article == 0) {
		prev.disabled = true;
		prev.classList.add("faded");
		next.disabled = false;
		next.classList.remove("faded");
	} else if (activeButton.dataset.article == articles.length - 1) {
		next.disabled = true;
		next.classList.add("faded");
		prev.disabled = false;
		prev.classList.remove("faded");
	} else {
		prev.disabled = false;
		next.disabled = false;
		prev.classList.remove("faded");
		next.classList.remove("faded");
	}
};

const setArticle = ({ article, index, nextArticle = null, prevArticle = null, initialLoad = false, goForward = true }) => {
	const loadingScreen = document.querySelector(".loading-screen");
	const images = document.querySelectorAll(".js-featured-image");
	const mobileImage = document.querySelector(".js-featured-mobile-image");
	const title = document.querySelector(".js-featured-title");
	const excerpt = document.querySelector(".js-featured-excerpt");
	const button = document.querySelector(".js-read-more-btn");
	const articleInfo = document.querySelector(".article-info");

	if (!initialLoad) {
		articleInfo.classList.add("fadeout");
	}

	const excerptText = (article.articleExcerpt) ? article.articleExcerpt : article.fullArticle.slice(0, 200) + " ...";

	setTimeout(() => {
		mobileImage.style.backgroundImage = `url(${article.photo.replace("~", "")})`;
		title.innerHTML = article.articleTitle;
		excerpt.innerHTML = excerptText;
		button.href = article.url.replace("~", "");
		articleInfo.classList.remove("fadeout");
	}, 350);

	for (dot of document.querySelectorAll(".pagination-dots .dot")) {
		if (dot.dataset.article == index) {
			dot.classList.add("active");
		} else {
			dot.classList.remove("active");
		}
	}
	loadingScreen.style.display = "none";

//swap images
	if (goForward) {
		for (image of images) {
			if (image.classList.contains("visible")) {
				image.setAttribute('class', "image js-featured-image onDeck slideIn")
				image.style.backgroundImage = `url(${nextArticle.photo ? nextArticle.photo.replace("~", "") : ""})`;
			} else if (image.classList.contains("onDeck")) {
				image.setAttribute('class', "image js-featured-image visible moveLeft")
				image.style.backgroundImage = `url(${article.photo.replace("~", "")})`;
			} else {
				image.style.backgroundImage = `url(${prevArticle.photo ? prevArticle.photo.replace("~", "") : ""})`;
			}
		}
	} else {
		for (image of images) {
			if (image.classList.contains('hidden')) {
				image.style.backgroundImage = `url(${article.photo.replace("~", "")})`;
				image.setAttribute('class', "image js-featured-image fadeIn visible")
			} else if (image.classList.contains('visible')) {
				image.setAttribute('class', "image js-featured-image onDeck moveRight")
			} else if (image.classList.contains('onDeck')) {
				image.setAttribute('class', "image js-featured-image hidden slideOut")
				
			}
		}
	}
	
	

	return index;
};


document.addEventListener("DOMContentLoaded", async () => {
	const blogContainer = document.querySelector(".blog-block .blog-articles .articles");
	const BlogBtn = document.querySelector('.js-load-more');

	
	if (blogContainer && BlogBtn) {
		BlogBtn.dataset.page = 0;
		getMoreArticles(blogContainer.dataset.page, 9, "all");
	}
	if (document.querySelector(".featured-articles")) {
		const featuredArticles = await getFeaturedArticles();
		let currentArticleIndex = 0;
		if (featuredArticles) {
			initBlogPagination(featuredArticles.length);
			setArticle({
				article: featuredArticles[currentArticleIndex],
				index: currentArticleIndex,
				nextArticle: featuredArticles[currentArticleIndex + 1],
				prevArticle: featuredArticles[currentArticleIndex - 1]
					? featuredArticles[currentArticleIndex - 1]
					: "",
				initialLoad: true
				});
			setButtons(featuredArticles);

			document.querySelector(".carousel-controls .next-button").addEventListener("click", () => {
				if (featuredArticles[currentArticleIndex + 1]) {
					currentArticleIndex++;
					currentArticleIndex = setArticle({
							article: featuredArticles[currentArticleIndex],
							index: currentArticleIndex,
							nextArticle: featuredArticles[currentArticleIndex + 1]
								? featuredArticles[currentArticleIndex + 1]
							: "",
							prevArticle: featuredArticles[currentArticleIndex - 1]
							? featuredArticles[currentArticleIndex - 1]
							: "",
						});
					setButtons(featuredArticles);
				}
				setButtons(featuredArticles);
			});
			document.querySelector(".carousel-controls .prev-button").addEventListener("click", () => {
				if (featuredArticles[currentArticleIndex - 1]) {
					currentArticleIndex--;
					currentArticleIndex = setArticle({
						article: featuredArticles[currentArticleIndex],
						index: currentArticleIndex,
						nextArticle: featuredArticles[currentArticleIndex + 1],
						prevArticle: featuredArticles[currentArticleIndex - 1]
							? featuredArticles[currentArticleIndex - 1]
							: "",
						goForward: false
					});
				}
				setButtons(featuredArticles);
			});
			document.querySelectorAll(".carousel-controls .dot").forEach((dot) => {
				dot.addEventListener("click", (e) => {
					const index = e.target.dataset.article;
					console.log(index + 1);
					currentArticleIndex = setArticle({
						article: featuredArticles[index],
						index: index,
						nextArticle: featuredArticles[Number(index) + 1] ? featuredArticles[Number(index) + 1] : "",
						prevArticle: featuredArticles[currentArticleIndex - 1]
							? featuredArticles[currentArticleIndex - 1]
							: "",
					});

					setButtons(featuredArticles);
				});
			});
		}
	}
});

/////////////////////////////////////////////////////
//       Blog Filter / Display Articles Logic      //
/////////////////////////////////////////////////////


const filter = document.querySelector('#blog-filter-select');
const BlogBtn = document.querySelector(".js-load-more");

if (filter) {

	document.addEventListener('DOMContentLoaded', () => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const category = urlParams.get('category')

		if (category) {
			filter.value = category;

			const event = new Event('change');

			// Dispatch it.
			filter.dispatchEvent(event);

		}
	})

	

	filter.addEventListener('change', () => {
		getMoreArticles(0, 9, filter.value);
		BlogBtn.dataset.page = 1;
	})
	BlogBtn.addEventListener('click', () => {
	getMoreArticles(BlogBtn.dataset.page, 9, filter.value, true);
})
}



